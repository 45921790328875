import React from "react"
import { Link } from "gatsby"

import Layout, { Wrapper, Spacer } from "../components/layout"
import SEO from "../components/seo"

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" />
    <Spacer h="5" />
    <Wrapper>
      <h1>Pricing</h1>
      <p>Welcome to pricing page</p>
      <Link to="/">Go back to Overview</Link>
    </Wrapper>
  </Layout>
)

export default Pricing
